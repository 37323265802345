import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

export interface EmailInterface {
  name: string;
  from: string;
  message: string;
  phone: string;
  subject: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public isLoading: boolean;
  private subs: Subscription[] = [];
  constructor(private http: HttpClient) {}

  public ngOnInit() {
    this.initForm();
  }

  public ngOnDestroy() {
    for (const subscription of this.subs) {
      subscription.unsubscribe();
    }
  }

  private initForm() {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      from: new FormControl(null, [Validators.required, Validators.email]),
      message: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      subject: new FormControl(null, [Validators.required]),
    });
    this.isLoading = false;
    for (const control in this.form.controls) {
      if (control) {
        this[control] = this.form.controls[control];
      }
    }
  }

  public isControlInvalid(controlName: string) {
    const control = this.form.get(controlName);
    return control.dirty && control.invalid;
  }

  public onSubmit() {
    this.isLoading = true;
    const message = this.form.getRawValue() as EmailInterface;
    const sub = this.http.post(environment.sesApi, message).subscribe();
    this.subs.push(sub);
    this.isLoading = false;
    this.form.reset();
  }
}
