import { Component } from '@angular/core';



@Component({
  selector: 'app-home-promo-block',
  templateUrl: './home-promo-block.component.html',
  styleUrls: ['./home-promo-block.component.scss'],
})
export class HomePromoBlockComponent {
  
}
