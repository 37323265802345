import { Component } from '@angular/core';

import { productsLinks, ProductEnum } from 'src/app/core/models';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
@Component({
  selector: 'app-certified-coins',
  templateUrl: './certified-coins.component.html',
  styleUrls: ['./certified-coins.component.scss'],
})
export class CertifiedCoinsComponent {
  public readonly btnSettings: IButtonSettings = {
    title: 'Request a demo',
  };
}
