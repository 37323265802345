import { Component, Input } from '@angular/core';

export interface IButtonSettings {
  title: string;
  link?: string;
  route?: string;
  callback?: () => void;
}

@Component({
  selector: 'app-top-block',
  templateUrl: './top-block.component.html',
  styleUrls: ['./top-block.component.scss'],
})
export class TopBlockComponent {
  @Input() public readonly title: string;
  @Input() public readonly text: string;
  @Input() public readonly text2: string;
  @Input() public readonly text3?: string;
  @Input() public readonly imgPath: string;
  @Input() public readonly btnSettings: IButtonSettings;
}
