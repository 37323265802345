import { Component } from '@angular/core';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import {
  aboutUsServices,
  IAboutUsService,
  ILicense,
  licenses,
  companyTitle,
} from 'src/app/core/models';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent {
  public readonly aboutUsServices: IAboutUsService[] = aboutUsServices;
  public readonly licenses: ILicense[] = licenses;
  //public readonly companyTitle: string = companyTitle;
  public readonly companyTitle: string = "Coinflex";
  public glowText: string = "flex";
  public readonly btnSettings: IButtonSettings = {
    title: 'Send me free quote ',
  };
  setGlowText(text: string){
    this.glowText = text;
  }
  cutGlow() {
    if(this.glowText ){
      
      return this.companyTitle.replace(this.glowText, "");
    }
    return;
  }
}
