export const environment = {
  sesApi: 'https://tx658ottgh.execute-api.eu-west-2.amazonaws.com/default/sendEmailFromLanding',
  production: false,
  companyTitle: 'Alpha Marketing',
  rootLink: '#',
  contacts: {
    supportEmail: 'support@coinflex.io',
    complianceEmail: 'compliance@coinflex.io',
    infoEmail: 'info@coinflex.io',
    partnersEmail: 'partners@coinflex.io',
    address:
      'Roosikrantsi tn 2 Kseklinna linnaosa,<br />Tallinn, Harju maakond',
    phone: '+3726028516',
    googleMapLink:
      // tslint:disable-next-line: max-line-length
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902.7861554290475!2d171.27634317848992!3d7.068144998400396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x650f1d3cb0240385%3A0x734ce247d5804668!2sAPEXIA%20Tech%20Ltd.!5e1!3m2!1sen!2skg!4v1710483691574!5m2!1sen!2skg'
  },
  apiUrl: '',
  licenceLinks: {
    walletService: '#',
    exchangeService: 'exchange.coinflex.io',
  },
  resourcesLinks: {
    terms: 'terms',
    policy: 'privacy-policy',
    cookies: 'cookies-policy',
    customer: 'acceptance-policy',
    merchant: 'merchant-acceptance',
    AML: 'aml-rules-virtual-currency-wallet-service'

  },
  socialLinks: {
    medium: '#',
    instagram: '#',
    twitter: '#',
    youtube: '#',
    telegram: '#',
    linkedin: '#',
  },
  walletLinks: {
    web: 'https://pay.elegro.eu/sign-in/',
    appStore: 'https://apps.apple.com/gb/app/elegro-crypto-fiat-wallet/id1443206753',
    playMarket: 'https://play.google.com/store/apps/details?id=paydeepp.elegro.io',
  },
  certifiedCoins: {
    web: 'https://exchange.coinflex.io',
    appStore: '#',
    playMarket: '#',
  },
};
