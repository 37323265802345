import { Component ,AfterViewChecked } from '@angular/core';

import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import { productsLinks, ProductEnum } from 'src/app/core/models';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
})
export class WalletComponent implements AfterViewChecked {
  public readonly btnSettings: IButtonSettings = {
    title: 'Request a demo',
  };
  ngAfterViewChecked() {
    //console.log('asd');
    //window.scrollTo(0, 0);
    }
}
