import { Component } from '@angular/core';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
import { companyTitle } from 'src/app/core/models';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-reasons-block',
  templateUrl: './home-reasons-block.component.html',
  styleUrls: ['./home-reasons-block.component.scss'],
})
export class HomeReasonsBlockComponent {
  public readonly contactUs = internalRoutes[InternalRouteEnum.CONTACT_US];
  //public readonly companyTitle: string = companyTitle.toLocaleLowerCase();
  public readonly companyTitle: string = 'coinflex';
  public readonly supportEmail: string = "info@info.com";
  public glowText: string = "flex";
  constructor(
    private readonly router: Router
  ) {

  }

  public readonly btnSettings: IButtonSettings = {
    title: 'Get a free quote now',
    callback: () => location.href=internalRoutes[InternalRouteEnum.CONTACT_US],

  };
  setGlowText(text: string){
    this.glowText = text;
  }
  cutGlow() {
    if(this.glowText ){
      
      return this.companyTitle.replace(this.glowText, "");
    }
    return;
  }
 goTo(event, add: string) {
  if (event.ctrlKey || event.metaKey){
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/${add}`])
    );

    window.open(url, '_blank');
    return;
  }
  const element = document.querySelector('#backnew');
  element.scrollIntoView();
  this.router.navigate(['/'+add] );
 } 
}
