import { Component, Inject } from '@angular/core';

import {
  footerCompanyRoutes,
  footerResouresLinks,
  footerResouresRoutes,
  IRoute,
  companyTitle,
} from 'src/app/core/models';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public readonly currentYear: string = String(new Date().getFullYear());
  public readonly footerCompanyRoutes: IRoute[] = footerCompanyRoutes;
  public readonly footerResouresRoutes: IRoute[] = footerResouresRoutes;
  public readonly footerResouresLinks: IRoute[] = footerResouresLinks;
  public readonly companyTitle: string = companyTitle;
  private readonly regPaths: any[] = ['SEO','SMM','PR','contact-us'];
  constructor(
    private readonly router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }
  public async goTo(route) {
    let href = window.location.href.split('/');
    let inner = href[3].split('#');
    let hash = inner[0];
    await this.router.navigate([`/${route.link}`] , { fragment: route.fragment });
    window.location.hash = ''; 
    window.location.hash = route.fragment;
  }
}
