import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';

import {
  contactsPhones,
  contactsEmails,
  contactsAdresses,
  contactsAddressGoogleMapLink,
} from 'src/app/core/models';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  public readonly btnSettings: IButtonSettings = {
    title: '',

  };
  public readonly phones: string[] = contactsPhones;
  public readonly emails: string[] = contactsEmails;
  
  public readonly addresses: string[] = contactsAdresses;

  public googleMapLink;


  constructor(sanitizer: DomSanitizer) {
    console.log(this.emails);
    this.googleMapLink = sanitizer.bypassSecurityTrustResourceUrl(
      contactsAddressGoogleMapLink,
    );
  }
}
