import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { HomeReasonsBlockComponent } from './pages/home/home-reasons-block/home-reasons-block.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { CertifiedCoinsComponent } from './pages/certified-coins/certified-coins.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsComponent } from './pages/terms/terms.component';
import { AMLComponent } from './pages/AML/AML.component';
import { MerchantAcceptanceComponent } from './pages/merchant-acceptance/merchant-acceptance.component';
import { CookiesComponent } from './pages/cookies/cookies.component';
import { AcceptancePolicyComponent } from './pages/acceptance-policy/acceptance-policy.component';
export interface IRouteData {
  showSquare: boolean;
}

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      showSquare: true,
      fragment: ''
    },
  },

  
  {
    path: 'SEO',
    component: WalletComponent,
    data: {
      showSquare: true,
      fragment: ''
    },
  },
  {
    path: 'SMM',
    component: AboutUsComponent,
    data: {
      showSquare: false,
      fragment: ''
    },
  },
  {
    path: 'PR',
    component: CertifiedCoinsComponent,
    data: {
      showSquare: true,
      fragment: ''
    },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: {
      showSquare: true,
      fragment: ''
    },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: 'terms',
    component: TermsComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: 'aml-rules-virtual-currency-wallet-service',
    component: AMLComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: 'merchant-acceptance',
    component: MerchantAcceptanceComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: 'cookies-policy',
    component: CookiesComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: 'acceptance-policy',
    component: AcceptancePolicyComponent,
    data: {
      showSquare: true,
    },
  },
  {
    path: '**',
    component: NotFoundComponent,
    data: {
      showSquare: false,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { 
      scrollPositionRestoration: 'enabled' ,
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
