import { Component, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { internalRoutes, InternalRouteEnum } from 'src/app/core/models';
import { IButtonSettings } from 'src/app/components/layouts/top-block/top-block.component';
import { homeServicesBlockIdToken , homeProductsBlockIdToken } from 'src/app/core/models';
import { OnInit } from '@angular/core';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  
  public readonly btnSettings: IButtonSettings = {
    title: 'Send me free quote ',
    callback: () => location.href=internalRoutes[InternalRouteEnum.CONTACT_US],

  };

  constructor(
    private readonly pageScrollService: PageScrollService,
    private router: Router,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
  }
  ngOnInit() {
    //console.log(window.location.hash);
    if(this.router.url.includes('#')){
      let hash = window.location.hash;
      window.location.hash = ''; 
      window.location.hash=hash;
      

    }
  }
  private goToProducts() {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${homeProductsBlockIdToken}`,
    });
  }
  private goToSrevices() {
    console.log('fire');
    
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: `#${homeServicesBlockIdToken}`,
    });
  }
}
