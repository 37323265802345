import { HomeComponent } from './home/home.component';
import { HomeProductsBlockComponent } from './home/products-block/home-products-block.component';
import { HomeReasonsBlockComponent } from './home/home-reasons-block/home-reasons-block.component';
import { HomePointsBlockComponent } from './home/home-points-block/home-points-block.component';
import { HomePromoBlockComponent } from './home/home-promo-block/home-promo-block.component';
import { CertifiedCoinsComponent } from './certified-coins/certified-coins.component';
import { CertifiedFeaturesBlockComponent } from './certified-coins/certified-features-block/certified-features-block.component';
import { CertifiedBannerBlockComponent } from './certified-coins/certified-banner-block/certified-banner-block.component';
import { CertifiedQuestionsComponent } from './certified-coins/certified-questions-block/certified-questions.component';

import { WalletComponent } from './wallet/wallet.component';
import { CryptoBannerComponent } from './wallet/crypto-banner/crypto-banner.component';
import { WalletFeaturesBlockComponent } from './wallet/wallet-features-block/wallet-features-block.component';
import { SupportedCurrenciesBlockComponent } from './wallet/supported-currencies-block/supported-currencies-block.component';
import { CryptoCurrenciesListComponent } from './wallet/supported-currencies-block/crypto-currencies-list/crypto-currencies-list.component';
import { FiatCurrenciesListComponent } from './wallet/supported-currencies-block/fiat-currencies-list/fiat-currencies-list.component';
import { WalletDesktopBlockComponent } from './wallet/wallet-desktop-block/wallet-desktop-block.component';

import { ContactUsComponent } from './contact-us/contact-us.component';
import { ContactFormComponent } from './contact-us/contact-form/contact-form.component';

import { NotFoundComponent } from './not-found/not-found.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { TermsComponent } from './terms/terms.component';
import { AMLComponent } from './AML/AML.component';
import { MerchantAcceptanceComponent } from './merchant-acceptance/merchant-acceptance.component';
import { CookiesComponent } from './cookies/cookies.component';
import { AcceptancePolicyComponent } from './acceptance-policy/acceptance-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

export const HOME_COMPONENTS = [
  HomeProductsBlockComponent,
  HomeReasonsBlockComponent,
  HomePointsBlockComponent,
  HomePromoBlockComponent,
];

export const CERTIFIED_COMPONENTS = [
  CertifiedFeaturesBlockComponent,
  CertifiedBannerBlockComponent,
  CertifiedQuestionsComponent,
];

export const WALLET_COMPONENTS = [
  CryptoBannerComponent,
  WalletFeaturesBlockComponent,
  WalletDesktopBlockComponent,
  SupportedCurrenciesBlockComponent,
  /* supported currencies components */
  CryptoCurrenciesListComponent,
  FiatCurrenciesListComponent,
];

export const CONTACT_US_COMPOENTS = [ContactFormComponent];

export const PAGES_COMPONENTS = [
  ...HOME_COMPONENTS,
  ...CERTIFIED_COMPONENTS,
  ...WALLET_COMPONENTS,
  ...CONTACT_US_COMPOENTS,
];

export const PAGES = [
  HomeComponent,
  NotFoundComponent,
  WalletComponent,
  CertifiedCoinsComponent,
  ContactUsComponent,
  AboutUsComponent,
  TermsComponent,
  AMLComponent,
  MerchantAcceptanceComponent,
  CookiesComponent,
  AcceptancePolicyComponent,
  PrivacyPolicyComponent,
  ...PAGES_COMPONENTS,
];
